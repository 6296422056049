<template>
  <v-row>

    <v-col cols="0" md="2"></v-col>

    <v-col cols="12" md="8">
      <v-card>
        <v-card-text>
          <base-subheading
            :subheading="user.displayName"
            :text="user.username"
          />

          <base-material-tabs color="success" vertical>
          
            <v-tab>
              {{ $t('views.profile.profile') }}
            </v-tab>

            <v-tab>
              {{ $t('views.profile.changePassword') }}
            </v-tab>

            <!-- User Info Table -->
            <v-tab-item>
              <v-card flat>

                <v-card-title class="pb-4">
                  <v-avatar size="96">
                    <v-img :src="require('@/assets/avatars/006-jupiter.svg')"></v-img>
                  </v-avatar>
                </v-card-title>
              
                <v-card-text>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td><v-icon>mdi-email</v-icon></td>
                        <td>{{ $t('views.profile.email') }}</td>
                        <td>{{ user.email }}</td>
                      </tr>
                      <template>
                        <tr v-if="user.level === 'superadmin'">
                          <td><v-icon>mdi-star</v-icon></td>
                          <td>You're a Super Admin!</td>
                          <td></td>
                        </tr>
                        <tr v-if="user.organization">
                          <td><v-icon>mdi-domain</v-icon></td>
                          <td>{{ $t('views.profile.organization') }}</td>
                          <td>{{ user.organization.name }}</td>
                        </tr>
                        <tr v-if="user.merchant">
                          <td><v-icon>mdi-store</v-icon></td>
                          <td>{{ $t('views.profile.merchant') }}</td>
                          <td>{{ user.merchant.name }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Change Password Tab -->
            <v-tab-item>
              <v-card flat class="px-6">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-form>
                          <v-text-field dense outlined type="password" :label="$t('views.profile.form.oldPassword')"
                            v-model="form.resetPassword.model.old" :disabled="form.resetPassword.isLoading"
                            :error-messages="validation.firstError('form.resetPassword.model.old')">
                          </v-text-field>

                          <v-text-field dense outlined type="password" :label="$t('views.profile.form.newPassword')"
                            v-model="form.resetPassword.model.new" :disabled="form.resetPassword.isLoading"
                            :error-messages="validation.firstError('form.resetPassword.model.new')">
                          </v-text-field>

                          <v-text-field dense outlined type="password" :label="$t('views.profile.form.confirm')"
                            v-model="form.resetPassword.model.confirm" :disabled="form.resetPassword.isLoading"
                            :error-messages="validation.firstError('form.resetPassword.model.confirm')">
                          </v-text-field>
                          
                          <v-btn tile :disabled="form.resetPassword.isLoading" color="warning"
                            @click="requestChangePassword">
                            {{ $t('views.profile.form.submit') }}
                          </v-btn>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-card-text>
              </v-card>
            </v-tab-item>

          </base-material-tabs>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="0" md="2"></v-col>

  </v-row>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  
  mounted() {

  },

  methods: {
    requestChangePassword() {
      this.$validate()
      .then(valid => {
        if (!valid) return;

        this.form.resetPassword.isLoading = true;
        axios.put(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/users/${this.user['id']}`,
          {
            displayName: this.user['displayName'],//FIXME: We don't change diaplay name for now.
            oldPassword: this.form.resetPassword.model.old,
            newPassword: this.form.resetPassword.model.new
          }
        )
        .then(response => {
          this.$notify({
            title: `Password updated!`,
            type: 'success'
          });

          // Clear the form.
          this.form.resetPassword.model = {
            old: '', new: '', confirm: ''
          };
          this.validation.reset();

          //TODO: Force sign-out??
        })
        .catch(err => {
          console.error(`Cannot change password`, err);
          this.$notify({
            title: `Cannot change password! Please check your inputs.`,
            type: 'error'
          });
        })
        .finally(() => {
          this.form.resetPassword.isLoading = false;
        });
      });
      
    }
  },

  data() {
    return {
      form: {
        resetPassword: {
          isLoading: false,
          model: {
            old: '',
            new: '',
            confirm: ''
          }
        }
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  validators: {
    'form.resetPassword.model.old': v => {
      return Validator.value(v).required().minLength(8);
    },
    'form.resetPassword.model.new': v => {
      return Validator.value(v).required().minLength(8).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*\s).{8,}$/, 'The password must contain at least one uppercase letter, one lowercase letter, and one digit, and cannot contain spaces.');
    },
    'form.resetPassword.model.confirm, form.resetPassword.model.new': (confirm, newPw) => {
      return Validator.value(confirm).required().match(newPw);
    },
  }
}
</script>